import React from "react";
import languageData from "../languages/languages.json";
class ArError extends React.Component {

    constructor(props) {
        super(props);
      }

    render() {
        return (
            <header className="App-content bg-backgroundcolor">

                <div>
                    {/* <div className="flex max-w-3xl"> */}

                    <div className="flex-initial max-w-none align-middle flex flex-col justify-center">
                        <span className="text-thirdcolor text-center font-light m-4">
                        {languageData[this.props.locale].arErrorMessage}
                        </span>
                    </div>
                </div>
            </header>
        );
    }
}
export default ArError;
