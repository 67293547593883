function addCustomEventListener(eventName, listener) {

    document.addEventListener(eventName, listener);
  }
  
  function removeCustomEventListener(eventName, listener) {
    document.removeEventListener(eventName, listener);

  }
  
  function triggerCustomEvent(eventName, data) {
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
  }
  
  export { addCustomEventListener, removeCustomEventListener, triggerCustomEvent};
