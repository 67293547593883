import React from "react";
import "mind-ar/dist/mindar-image.prod.js";
import "aframe";
import "mind-ar/dist/mindar-image-aframe.prod.js";
import ArComponent from "./ArComponent";
import SceneSpringComponent from "./SceneSpringComponent";
import SceneWinterComponent from "./SceneWinterComponent";
import SceneSummerComponent from "./SceneSummerComponent";
import SceneAutumnComponent from "./SceneAutumnComponent";
import Modal from "./modal";
import languageData from '../languages/languages.json';
import ArError from "./ArError";
import { triggerCustomEvent } from "./events"

//these are assigned to button onclicks below, they launch custom events constructed with events.js. Each scene has an addCustomlistener for all camera commands. It might work with ordinary "addeventlistener" as well
const zoomIn = () => {
  triggerCustomEvent('zoomIn');
}
const zoomOut = () => {
  triggerCustomEvent('zoomOut');
}

const stopAutoRotate = () => {
  triggerCustomEvent('stopAutoRotate');
}
const startAutoRotate = () => {
  triggerCustomEvent('startAutoRotate');
}
const removeEventListeners = () => {
  triggerCustomEvent("removeEventListeners");
}
const rotateLeft = () => {
  triggerCustomEvent("cameraButtonLeft");
}
const rotateRight = () => {
  triggerCustomEvent("cameraButtonRight");
}
const rotateUp = () => {
  triggerCustomEvent("cameraButtonUp");
}
const rotateDown = () => {
  triggerCustomEvent("cameraButtonDown");
}

class ArView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      season: null,
      showModal: false,
      modalData: null,
      reset: false,
      sceneLoaded: false,
      arError: false,
      imageIsTracked: false,
      menuOpen: false,
      arSceneLoaded: false,
    };
  }

  handleSceneClick = () => {
    this.setState({ clicked: true });
  };
  handleSeasonClick = (seasonClicked) => {
    if (this.state.arSceneLoaded) {
      this.setState({ season: seasonClicked });
    }
  };
  handleModal = (data) => {
    this.setState({ showModal: true, modalData: data });
    triggerCustomEvent('stopAutoRotate');
  };
  handleModalClose = () => {
    this.setState({ showModal: false, modalData: null });

    //only start autorotating if the accessibility menu is not open
    if (!this.state.menuOpen) {
      triggerCustomEvent('startAutoRotate');
    }
  };
  handLeSceneLoaded = (stateToSet) => {
    this.setState({ sceneLoaded: stateToSet });
    //determine if camera should autorotate or not
    if (this.state.menuOpen) {
      stopAutoRotate();
    }
    else {
      //startAutoRotate();
    }

  };
  handleArLoaded = () => {
    this.setState({ arSceneLoaded: true });
  }
  handleArStopped = () => {
    this.setState({ arSceneLoaded: false });
  }
  handLeArError = () => {
    this.setState({ arError: true });
  };
  handleImageFound = () => {
    this.setState({ imageIsTracked: true });
  };
  handleImageLost = () => {
    this.setState({ imageIsTracked: false });
  };
  handleResize = () => {
    //only fire the resize event if the ar scene is loaded and running - thus prevent multiple ar scene starts being queued and leading to Mind AR related error
    if (this.state.arSceneLoaded) {
      triggerCustomEvent('resizeNow');
    }
  };
  handleAccessibilityMenuClick = () => {

    //Todo: prettier toggle statement?
    if (this.state.menuOpen) {
      this.setState({ menuOpen: false });
      startAutoRotate();
    }
    else {
      this.setState({ menuOpen: true });
      stopAutoRotate();

    }
  };
  handleThreeDButtonClick = () => {
    //emulate AR model click. Pressing the button always starts the 3D from the season defined in threeDButtonClicked -const at ArComponent.js
    triggerCustomEvent("stopArSystem");
    triggerCustomEvent("threeDButtonClicked");
  }
  handleNavigation = (command) => {


    this.setState({
      reset: true,
      sceneLoaded: false,

    })
    if (command == "next") {
      switch (this.state.season) {
        case "kesa":
          this.setState({
            season: "syksy"
          })
          break;
        case "kevat":
          this.setState({
            season: "kesa"
          })
          break;
        case "syksy":
          this.setState({
            season: "talvi"
          })
          break;
        case "talvi":
          this.setState({
            season: "kevat"
          })
          break;


      }
    } else {
      switch (this.state.season) {
        case "kesa":
          this.setState({
            season: "kevat"
          })
          break;
        case "kevat":
          this.setState({
            season: "talvi"
          })
          break;
        case "syksy":
          this.setState({
            season: "kesa"
          })
          break;
        case "talvi":
          this.setState({
            season: "syksy"
          })
          break;

      }
    }
  }

  render() {
    return (
      <div className="videocontainer">
        {this.state.arError && (
          <ArError
            locale={this.props.locale}
          />
        )}

        {this.state.season !== null && this.state.sceneLoaded && !this.state.showModal &&
          <div>
            <button className="top-0 w-24 text-xs left-0 p-5 m-5 fixed bg-maincolor text-secondarycolor z-20" tabIndex="0" onClick={() => { this.handleNavigation('previous') }}>{languageData[this.props.locale].previousSeason}</button>
            <div className=" z-10 absolute inset-x-0 top-8">
              <h1 className="text-center text-secondarycolor text-xl" style={{ textShadow: "1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000" }}>{languageData[this.props.locale][this.state.season]}</h1>
            </div>
            <button tabIndex="0" className="top-0  w-24 text-xs right-0 p-5 m-5 fixed bg-maincolor text-secondarycolor z-20" onClick={() => { this.handleNavigation('next') }}>{languageData[this.props.locale].nextSeason}</button>
            <div>
              <button className="fixed w-12 h-12  bottom-4 left-4 z-20" onClick={() => { this.handleAccessibilityMenuClick() }}><img alt={languageData[this.props.locale].accessibilityMenu} src="icons/access_inact.png" /></button>

            </div>
          </div>

        }
        {!this.state.clicked && !this.state.arError && this.state.season === null && this.state.arSceneLoaded && (
          <>
            <button tabIndex="0" className="bottom-0  w-16 text-xs right-0 p-5 m-5 fixed bg-maincolor text-secondarycolor z-20" onClick={() => { this.handleThreeDButtonClick() }}>3D</button>
          </>
        )}
        {!this.state.clicked && !this.state.arError && this.state.season === null && (
          <>


            <div className=" z-10 absolute inset-x-0 top-8">
              <h1 className="text-center text-secondarycolor text-xl" style={{ textShadow: "1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000" }}>
                {this.state.imageIsTracked && languageData[this.props.locale].arInteractionInstruction}
                {!this.state.imageIsTracked && languageData[this.props.locale].arScanInstruction}
              </h1>
            </div>
            <ArComponent
              sceneClicked={this.handleSceneClick}
              seasonClicked={this.handleSeasonClick}
              imageFound={this.handleImageFound}
              imageLost={this.handleImageLost}
              arLoaded={this.handleArLoaded}
              arStopped={this.handleArStopped}
              arReady={this.state.arSceneLoaded}
              resize={this.handleResize}
              arError={this.handLeArError} /></>
        )}
        {this.state.clicked && !this.state.arError && this.state.season === "talvi" && (
          <SceneWinterComponent
            reset={this.state.reset}
            openModal={this.handleModal}
            seasonClicked={this.state.season}
            sceneLoaded={this.handLeSceneLoaded} />
        )}

        {this.state.clicked && !this.state.arError && this.state.season === "kevat" && (
          <SceneSpringComponent
            reset={this.state.reset}
            openModal={this.handleModal}
            seasonClicked={this.state.season}
            sceneLoaded={this.handLeSceneLoaded} />
        )}
        {this.state.clicked && !this.state.arError && this.state.season === "kesa" && (
          <SceneSummerComponent
            reset={this.state.reset}
            openModal={this.handleModal}
            seasonClicked={this.state.season}
            sceneLoaded={this.handLeSceneLoaded}
          />
        )}
        {this.state.clicked && !this.state.arError && this.state.season === "syksy" && (
          <SceneAutumnComponent
            reset={this.state.reset}
            openModal={this.handleModal}
            seasonClicked={this.state.season}
            sceneLoaded={this.handLeSceneLoaded} />
        )}
        {this.state.showModal && this.state.modalData !== null && (
          <Modal data={this.state.modalData} locale={this.props.locale} closeModal={this.handleModalClose} />
        )}
        {this.state.season !== null && this.state.sceneLoaded && !this.state.showModal && this.state.menuOpen && (
          <div>
            <div className="fixed inset-x-0 bottom-20">
              <button className=" w-12 h-12 z-20" tabIndex="0" onClick={rotateUp}><img alt={languageData[this.props.locale].upAlt} src="icons/arrowU_inact.png" /></button>
            </div>
            <div className=" fixed inset-x-0 bottom-10 ">
              <button className=" w-12 h-12 z-20 mx-3" tabIndex="0" onClick={rotateLeft}><img alt={languageData[this.props.locale].leftAlt} src="icons/arrowL_inact.png" /></button>
              <button className=" w-12 h-12 z-20 mx-3" tabIndex="0" onClick={rotateRight}><img alt={languageData[this.props.locale].rightAlt} src="icons/arrowR_inact.png" /></button>
            </div>
            <div className=" fixed inset-x-0 bottom-0">
              <button className=" w-12 h-12 z-20" tabIndex="0" onClick={rotateDown}><img alt={languageData[this.props.locale].downAlt} src="icons/arrowD_inact.png" /></button>
            </div>
            <div>
              <button className="fixed bottom-4 right-4 w-12 h-12 z-20" tabIndex="0" onClick={zoomOut}><img alt={languageData[this.props.locale].zoomOutAlt} src="icons/minus_inact.png" /></button>
              <button className="fixed bottom-20 right-4 w-12 h-12 z-20" tabIndex="0" onClick={zoomIn}><img alt={languageData[this.props.locale].zoomInAlt} src="icons/plus_inact.png" /></button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default ArView;
