import React from "react";
import "./App.css";
import Language from "./components/language";
import Instructions from "./components/instructions";
import ArView from "./components/arview";
class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: null,
      start: false,

    };
  }
  handleLanguage = (langValue) => {
    this.setState({ locale: langValue });
  };
  handleStart = () => {
    this.setState({ start: true });
  };
  showInstructions = () => {
    this.setState({ start: false });
  };
  navigateToLanguage = () => {
    this.setState({ locale: null });
  };
  showLanguage = () => {
    this.setState({ locale: null });
  };
  render() {
    return (
      <div className="App">
        {/* sivu 1 */}
        {this.state.locale === null && (
          <Language onSelectLanguage={this.handleLanguage} locale={this.state.locale} />
        )}
        {this.state.locale !== null && !this.state.start && (
          <Instructions onSelectStart={this.handleStart} onNavigateBack={this.navigateToLanguage} locale={this.state.locale}/>
      
        )}
        {this.state.locale !== null && this.state.start && (
          <ArView onShowLanguage={this.showLanguage} onShowInstructions={this.showInstructions} locale={this.state.locale} />
        )}
      </div>
    );
  }
}
export default MainPage;
