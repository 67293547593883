import React, { useEffect, useRef } from "react";
import BackgroundGradient from "./background-gradient.js";
import OrbitControls from "./orbit-controls.js";
import AnimationMixer from "./animation-mixer.js"
import { addCustomEventListener, removeCustomEventListener } from "./events";

export default (props) => {
    const sceneRef = useRef(null);

    const text1Ref = useRef(null);
    const text2Ref = useRef(null);
    const text3Ref = useRef(null);
    const text4Ref = useRef(null);
    const text5Ref = useRef(null);
    const image1Ref = useRef(null);
    const modelRef = useRef(null);
    const camRef = useRef(null);
    let currentContent = null;

    function sceneLoaded() {
        props.sceneLoaded(true);
    }

    const handleZoomOutButton = () => {
        if (camRef.current) {
            camRef.current.setAttribute("orbit-controls", "autoRotate", "false");
            camRef.current.components['orbit-controls'].zoomOut();
        }
    }
    const handleZoomInButton = () => {
        if (camRef.current) {
            camRef.current.setAttribute("orbit-controls", "autoRotate", "false");
            camRef.current.components['orbit-controls'].zoomIn();
        }
    }
    const handleStopAutorotation = () => {
        if (camRef.current) {
            camRef.current.setAttribute("orbit-controls", "autoRotate", "false");
        }
    }
    const handleStartAutorotation = () => {

        if (camRef.current) {
            camRef.current.setAttribute("orbit-controls", "autoRotate", "true");
        }
    }
    const handleLeftButton = () => {

        if (camRef.current) {
            camRef.current.components['orbit-controls'].buttonRotateLeft();
        }
    }
    const handleRightButton = () => {

        if (camRef.current) {
            camRef.current.components['orbit-controls'].buttonRotateRight();
        }
    }
    const handleUpButton = () => {

        if (camRef.current) {
            camRef.current.components['orbit-controls'].buttonRotateUp();
        }
    }
    const handleDownButton = () => {

        if (camRef.current) {
            camRef.current.components['orbit-controls'].buttonRotateDown();
        }
    }

    //#region click functions
    const onTextClick1 = () => {
        props.openModal({
            type: "text",
            content: "springText1",
        });
    }

    const onTextClick2 = () => {
        props.openModal({
            type: "text",
            content: "springText2",
        });
    }
    const onTextClick3 = () => {
        props.openModal({
            type: "text",
            content: "springText3",
        });
    }
    const onTextClick4 = () => {
        props.openModal({
            type: "text",
            content: "springText4",
        });
    }
    const onTextClick5 = () => {
        props.openModal({
            type: "text",
            content: "springText5",
        });
    }
    const onImageClick1 = () => {
        props.openModal({
            type: "image",
            image: "norppakivella.JPG",
            imageText: "springImageText1",
            altText: "springAlt1",
        });
    }
    //#endregion

    const onText1Pressed = (event) => {
        if (event.key === "Enter") {
            onTextClick1();
        }
    }
    const onText2Pressed = (event) => {
        if (event.key === "Enter") {
            onTextClick2();
        }
    }
    const onText3Pressed = (event) => {
        if (event.key === "Enter") {
            onTextClick3();
        }
    }
    const onText4Pressed = (event) => {
        if (event.key === "Enter") {
            onTextClick4();
        }
    }
    const onText5Pressed = (event) => {
        if (event.key === "Enter") {
            onTextClick5();
        }
    }
    const onImage1Pressed = (event) => {
        if (event.key === "Enter") {
            onImageClick1();
        }

    }
    const onFocusReceived = (params) => {
        currentContent = document.activeElement;

        //get current #src attribute and determine which icon to display
        let src = currentContent.getAttribute("src");
        switch (src) {
            case "#iconText":
                currentContent.setAttribute("material", "src", "#iconTextSelected");
                break;
            case "#iconAudio":
                currentContent.setAttribute("material", "src", "#iconAudioSelected");
                break;
            case "#iconVideo":
                currentContent.setAttribute("material", "src", "#iconVideoSelected");
                break;
            case "#iconImage":
                currentContent.setAttribute("material", "src", "#iconImageSelected");
                break;
            default:
                break;
        }
    }



    const onFocusLost = () => {

        let src = currentContent.getAttribute("src");
        switch (src) {
            case "#iconText":
                currentContent.setAttribute("material", "src", "#iconText");
                break;
            case "#iconAudio":
                currentContent.setAttribute("material", "src", "#iconAudio");
                break;
            case "#iconVideo":
                currentContent.setAttribute("material", "src", "#iconVideo");
                break;
            case "#iconImage":
                currentContent.setAttribute("material", "src", "#iconImage");
                break;
            default:
                break;
        }
    }

    useEffect(() => {

        //#region Add listeners
        //add camera control event listeners
        addCustomEventListener("zoomIn", () => handleZoomInButton());
        addCustomEventListener("zoomOut", () => handleZoomOutButton());
        addCustomEventListener("stopAutoRotate", () => handleStopAutorotation());
        addCustomEventListener("startAutoRotate", () => handleStartAutorotation());
        addCustomEventListener("cameraButtonLeft", () => handleLeftButton());
        addCustomEventListener("cameraButtonRight", () => handleRightButton());
        addCustomEventListener("cameraButtonUp", () => handleUpButton());
        addCustomEventListener("cameraButtonDown", () => handleDownButton());

        // add click listeners
        text1Ref.current.addEventListener("click", onTextClick1, false);
        text2Ref.current.addEventListener("click", onTextClick2, false);
        text3Ref.current.addEventListener("click", onTextClick3, false);
        text4Ref.current.addEventListener("click", onTextClick4, false);
        text5Ref.current.addEventListener("click", onTextClick5, false);
        image1Ref.current.addEventListener("click", onImageClick1, false);

        sceneRef.current.addEventListener("loaded", sceneLoaded);
        //add keypress listeners
        text1Ref.current.addEventListener("keypress", onText1Pressed, false);
        text2Ref.current.addEventListener("keypress", onText2Pressed, false);
        text3Ref.current.addEventListener("keypress", onText3Pressed, false);
        text4Ref.current.addEventListener("keypress", onText4Pressed, false);
        text5Ref.current.addEventListener("keypress", onText5Pressed, false);
        image1Ref.current.addEventListener("keypress", onImage1Pressed, false);


        //add focus listeners
        text1Ref.current.addEventListener("focus", onFocusReceived, false);
        text2Ref.current.addEventListener("focus", onFocusReceived, false);
        text3Ref.current.addEventListener("focus", onFocusReceived, false);
        text4Ref.current.addEventListener("focus", onFocusReceived, false);
        text5Ref.current.addEventListener("focus", onFocusReceived, false);
        image1Ref.current.addEventListener("focus", onFocusReceived, false);

        // add blur / lose focus listeners
        text1Ref.current.addEventListener("blur", onFocusLost, false);
        text2Ref.current.addEventListener("blur", onFocusLost, false);
        text3Ref.current.addEventListener("blur", onFocusLost, false);
        text4Ref.current.addEventListener("blur", onFocusLost, false);
        text5Ref.current.addEventListener("blur", onFocusLost, false);
        image1Ref.current.addEventListener("blur", onFocusLost, false);

        //#endregion
        return () => {
            window.THREE.Cache.clear();
            if (sceneRef.current != null) {
                sceneRef.current.renderer.renderLists.dispose();
                sceneRef.current.renderer.dispose();
                sceneRef.current.renderer.forceContextLoss();

            }
        }

    }, []);

    return (
        <a-scene ref={sceneRef} color-space="sRGB" embedded renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false" shadow="type: basic" >
            <a-assets>
                <a-asset-item id="kevat" src="sektori_kevat.glb"></a-asset-item>
                <a-asset-item id="norppa" src="norppa_ani_kevat.glb"></a-asset-item>
                <img id="iconText" src="iconText.png" />
                <img id="iconTextSelected" src="iconTextSelected.png" />
                <img id="iconVideo" src="iconVideo.png" />
                <img id="iconVideoSelected" src="iconVideoSelected.png" />
                <img id="iconAudio" src="iconAudio.png" />
                <img id="iconAudioSelected" src="iconAudioSelected.png" />
                <img id="iconImage" src="iconImage.png" />
                <img id="iconImageSelected" src="iconImageSelected.png" />
            </a-assets>
            <a-entity geometry="primitive: sphere; radius:65"
                material="shader: background-gradient; colorTop: #D7EFFF; colorBottom: #C6C6C6; side: back;"></a-entity>

            <a-entity light="type: ambient; color: #bfecff; intensity: 0.5"></a-entity>
            <a-entity light="type: directional; color: #f7d9a8; castShadow: true; shadowBias: -0.0001; shadowMapHeight: 512; shadowMapWidth: 512;  intensity: 1" position="-2.5 1.5 -1"></a-entity>

            <a-camera ref={camRef} orbit-controls="
                autoRotate: true;
                autoRotateSpeed: 0.05;
                rotateSpeed: 0.05;
                target: #sector;
                enableDamping: true;
                enablePan: false;
                dampingFactor: 0.25;
                rotateSpeed:0.14;
                zoomSpeed: 0.5;
                minDistance: 3.75;
                maxDistance: 7;
                minPolarAngle: 0.75;
                maxPolarAngle: 1.25;
                "
                position="0 0 0" wasd-controls="enabled: false" look-controls="enabled: false" cursor="fuse: false; rayOrigin: mouse;" raycaster="far: 10000; objects: .clickable"></a-camera>
            <a-entity id="sector" rotation="0 90 0" position="0 0 0" scale="10 10 10">
                <a-gltf-model class="clickable" ref={modelRef} shadow="receive: true; cast:true" src="#kevat" rotation="0 0 0" scale="1 1 1"></a-gltf-model>
                <a-image ref={text5Ref} tabIndex="0" class="clickable" src="#iconText" scale=".05  .05  .05 " shadow="receive: false; cast:false" animation="property: scale; dir:alternate; to: 0.07 0.07 0.07; dur: 6000; easing: easeInElastic; loop: true" position="0.14 0.05 -0.013" rotation="-90 -90 0" event-set__loaded="_delay: 1500"></a-image>
                <a-image ref={text2Ref} tabIndex="0" class="clickable" src="#iconText" scale=".05  .05  .05 " shadow="receive: false; cast:false" animation="property: scale; dir:alternate; to: 0.07 0.07 0.07; dur: 6000; easing: easeInElastic; loop: true" position="0.023 0.01 -0.134" rotation="-90 -90 0" event-set__loaded="_delay: 1500"></a-image>
                <a-image ref={image1Ref} tabIndex="0" class="clickable" src="#iconImage" scale=".05  .05  .05 " shadow="receive: false; cast:false" animation="property: scale; dir:alternate; to: 0.07 0.07 0.07; dur: 6000; easing: easeInElastic; loop: true" position="0.032 0.02 0.095" rotation="-90 -90 0" event-set__loaded="_delay: 1500"></a-image>
                <a-image ref={text4Ref} tabIndex="0" class="clickable" src="#iconText" scale=".05  .05  .05 " shadow="receive: false; cast:false" animation="property: scale; dir:alternate; to: 0.07 0.07 0.07; dur: 6000; easing: easeInElastic; loop: true" position="0.06 0.01 0.237" rotation="-90 -90 0" event-set__loaded="_delay: 1500"></a-image>
                <a-image ref={text1Ref} tabIndex="0" class="clickable" src="#iconText" scale=".05  .05  .05 " shadow="receive: false; cast:false" animation="property: scale; dir:alternate; to: 0.07 0.07 0.07; dur: 6000; easing: easeInElastic; loop: true" position="-0.19 0.01 -0.03" rotation="-90 -90 0" event-set__loaded="_delay: 1500"></a-image>
                <a-image ref={text3Ref} tabIndex="0" class="clickable" src="#iconText" scale=".05  .05  .05 " shadow="receive: false; cast:false" animation="property: scale; dir:alternate; to: 0.07 0.07 0.07; dur: 6000; easing: easeInElastic; loop: true" position="-0.19 0.01 0.06" rotation="-90 -90 0" event-set__loaded="_delay: 1500"></a-image>
                <a-gltf-model shadow="cast:true" src="#norppa" rotation="3 45 -5" position="-0.225 0.008 0.014" animation-mixer="" scale="0.5 0.5 0.5"></a-gltf-model>
            </a-entity>
        </a-scene>
    )
};
