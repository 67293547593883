// type : {props.data.type}
// url : {props.data.url}
// content : {props.data.content}
// header : {props.data.header}
import contents from "../languages/contents.json";
import languages from "../languages/languages.json";

export default (props) => {
  return (
    <div
      className="fixed max-h-screen overflow-y-auto min-w-screen top-0 right-0 left-0 z-40 bg-secondarycolor m-10 p-3 rounded-md"
      style={{ maxHeight: "90%" }}
    >
      {props.data.type === "text" && (
        <div className="font-light"
          dangerouslySetInnerHTML={{
            __html: contents[props.locale][props.data.content],
          }}
        ></div>
      )}
      {props.data.type === "video" && (
        <div>
          <iframe
            title="video"
            style={{ display: "initial" }}
            src={props.data.url}
          />
        </div>
      )}
      {props.data.type === "audio" && (
        <div>
          <audio
            src={props.data.source}
            controls controlsList="nodownload noplaybackrate" autoPlay
            style={{ display: "initial", maxWidth: "90%" }}
          />
          <div className="p-1 font-light"
            dangerouslySetInnerHTML={{
              __html: contents[props.locale][props.data.content],
            }}
          ></div>
        </div>
      )}
      {props.data.type === "image" && (
        <div>
          <img
            src={props.data.image}
            alt={contents[props.locale][props.data.altText]}
            style={{ display: "initial" }}
          />
          <div className="p-1 font-light"
            dangerouslySetInnerHTML={{
              __html: contents[props.locale][props.data.imageText],
            }}
          ></div>
        </div>
      )}
      <div className="row p-3">
        <button
          className="bg-maincolor hover:bg-maincolor-600 text-secondarycolor py-2 px-4 rounded-full"
          onClick={props.closeModal}
        >
          {languages[props.locale].close}
        </button>
      </div>
    </div>
  );
};
