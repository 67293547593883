import React from "react";
import languageData from "../languages/languages.json";

class Language extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleClick(locale) {
    this.props.onSelectLanguage(locale);
  }
  // language has not been selected at this point so the texts on this view remain hardcoded
  render() {
    return (
      <header className="App-content bg-backgroundcolor">
        <h1 className="text-maincolor text-4xl p-10">{languageData['fi'].appName}</h1>
        <h2 className="text-maincolor text-2xl"> {languageData['fi'].languageSelectionHeader}</h2>
        <h2 className="text-maincolor text-2xl"> {languageData['en'].languageSelectionHeader}</h2>
        <div className="row p-5">

          <button
            className="bg-secondarycolor text-thirdcolor font-light p-4 m-2"
            onClick={() => {
              this.handleClick('fi');
            }}
          >
            Suomi
          </button>
          <button
            className="bg-secondarycolor text-thirdcolor font-light p-4 m-2"
            onClick={() => {
              this.handleClick('en');
            }}
          >
            English
          </button>
        </div>
        <div className="row p-5 columns-3">
          <img style={{ 'width': '64px' }} alt="EU LIFE logo" src="lifelogo.jpg" />
          <img style={{ 'width': '64px' }} alt="Yhteinen saimaannorppamme logo" src="saimaannorppammelogo.jpg" />
          <img style={{ 'width': '64px' }} alt="Natura 2000 logo" src="natura2000logo.jpg" />
        </div>
        <div className="py-2 text-xs">
        <a href='https://www.metsa.fi/projekti/yhteinen-norppamme-life/' style={{ color: "blue", textDecoration: "underline"}}  target="_blank">Yhteinen saimaannorppamme- LIFE-hankkeen verkkosivut</a>
        </div>
        <div className="py-2 text-xs">
        <a href='https://www.metsa.fi/en/project/our-saimaa-seal-life/' style={{ color: "blue", textDecoration: "underline"}}  target="_blank">Our Saimaa Seal LIFE website</a>
        </div>
        <div className="py-2 text-xs">
        <a href='https://www.luontoon.fi/saimaannorppa' style={{ color: "blue", textDecoration: "underline"}}  target="_blank" >Luontoon-verkkosivut</a>
        </div>
      </header>
    );
  }
}
export default Language;
